import React from "react";
import './Spinner.css';

function Spinner() {
  return (
    <div className="spinner">
      {/* <div className="circle"></div> */}
    </div>
  );
}

export default Spinner;
